<template>
  <div>
    <v-row>
      <v-col class="d-flex" style="align-items: center">
        <v-img
          src="/img/icons/info-circle.svg"
          height="1rem"
          width="1rem"
          style="flex-grow: 0; margin-right: 5px"
        />
        <p class="font-weight-bold">
          {{ $t("confirmation.checkInformation") }}
        </p>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col class="contact-info-box">
        <h2>{{ $t("confirmation.contactInformation") }}</h2>
        <div class="mb-4" v-if="!this.$store.state.addContactInfo">
          {{ $t("confirmation.anonymous") }}
        </div>
        <div class="mb-4" v-else>
          <h3>{{ $t("contactInfo.name") }}</h3>
          <p>{{ this.$store.state.contactName }}</p>
          <h3>{{ $t("contactInfo.email") }}</h3>
          <p>{{ this.$store.state.contactEmail }}</p>
          <h3>{{ $t("contactInfo.phone") }}</h3>
          <p>{{ this.$store.state.contactPhone }}</p>
        </div>
        <div v-if="!this.$store.state.addNotificationEmail">
          <h2>{{ $t("confirmation.noEmailNotifications") }}</h2>
        </div>
        <div v-else>
          <h2>{{ $t("confirmation.emailNotifications") }}</h2>
          <p>{{ this.$store.state.notificationEmail }}</p>
          <div class="d-flex" style="margin-top: 5px; align-items: center" v-if="this.$store.state.organization.orgId !== '426834'">
            <v-img
              src="/img/icons/info-circle.svg"
              height="1rem"
              width="1rem"
              style="flex-grow: 0; margin-right: 5px"
            />
            <p>
              <strong
                >{{ $t("confirmation.emailNotShared") }}
                {{ this.$store.state.organization.name }} -{{
                  $t("confirmation.toOrganization")
                }}</strong
              >
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col>
        <h2>{{ $t("reportForm.subject") }}</h2>
        <p>{{ this.$store.state.whatHappened }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>{{ $t("reportForm.time") }}</h2>
        <p>{{ this.$store.state.whenHappened }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>{{ $t("reportForm.place") }}</h2>
        <p>{{ this.$store.state.whereHappened }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>{{ $t("reportForm.details") }}</h2>
        <p>{{ this.$store.state.moreInformation }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>{{ $t("contactInfo.addedFiles") }}</h2>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(file, index) in this.$store.state.addedFiles"
            :key="index"
          >
            <a :href="file[0].url">{{ file[0].name }}</a>
          </v-list-item>
        </v-list-item-group>
      </v-col>
    </v-row>
    <v-btn
      :to="`/${this.$store.state.name}/yhteystiedot`"
      depressed
      color="#424752"
      style="color: #fff; font-size: 1rem; position: absolute; left: 12px"
      class="my-8"
    >
      {{ $t("general.previous") }}
    </v-btn>
    <v-btn
      depressed
      color="#424752"
      style="color: #fff; font-size: 1rem; position: absolute; right: 12px"
      class="my-8"
      @click="handleSend"
    >
      {{ $t("general.send") }}
    </v-btn>
    <ConfirmBox
      :visible="showAlert"
      @send-without-files="sendWithoutFiles"
      @cancel="cancel"
    />
  </div>
</template>

<style scoped>
.contact-info-box {
  border: 1px solid #9fa0ab;
  background-color: #f6f6f7;
  padding: 25px;
}

p {
  margin: 0;
}

h2 {
  font-size: 1.2rem;
  font-weight: 500;
}

.contact-info-box h3 {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 10px;
}
</style>

<script>
import ConfirmBox from "./ConfirmBox.vue";

export default {
  components: {
    ConfirmBox,
  },
  data() {
    return {
      organisation: {
        name: this.$route.params.report,
      },
      fileList: [],
      canSave: true,
      saveWithoutFiles: false,
      showAlert: false,
    };
  },
  methods: {
    sendWithoutFiles() {
      this.showAlert = false;
      this.saveWithoutFiles = true;
      this.canSave = true;
      this.handleSend();
    },
    cancel() {
      this.canSave = true;
      this.showAlert = false;
    },
    handleSend: function () {
      if (
        this.$store.state.addedFiles === null ||
        this.$store.state.addedFiles.length === 0
      ) {
        this.saveWithoutFiles = true;
      }
      if (this.saveWithoutFiles) {
        this.send();
      } else {
        this.sendFiles().then(async () => this.send());
      }
    },
    send: async function () {
      var url = process.env.VUE_APP_VIHJAA_ENDPOINT + "/incident/report";
      var data = {
        incidentTitle: this.$store.state.whatHappened,
        timeOfIncident: this.$store.state.whenHappened,
        placeOfIncident: this.$store.state.whereHappened,
        content: this.$store.state.moreInformation,
        organizationId: this.$store.state.organization.orgId,
        channelId: this.$store.state.channelId,
      };
      if (
        this.$store.state.sendedFiles != null &&
        this.$store.state.sendedFiles.length > 0
      ) {
        data["attachments"] = this.$store.state.sendedFiles;
      }
      if (this.$store.state.addNotificationEmail) {
        data["notificationEmail"] = this.$store.state.notificationEmail;
      }
      if (this.$store.state.addContactInfo) {
        data["reporter"] = {
          name: this.$store.state.contactName,
          email: this.$store.state.contactEmail,
          phone: this.$store.state.contactPhone,
        };
      }
      if (this.canSave) {
        await this.axios
          .post(url, data, { headers: { "Content-Type": "application/json" } })
          .then((r) => {
            this.$store.commit("updateReportResponse", r);
            this.$router.push(
              `/${this.$store.state.organization.urlName}/vahvistus`
            );
          })
          .catch((e) => {
            console.log(e);
            this.$store.commit("setSnack", {
              text:
                this.$i18n.locale == "fi"
                  ? "Ilmoituksen lähetyksessä virhe!"
                  : "Error sending report!",
              message: "",
              color: "error",
            });
          });
      }
    },
    sendFiles: async function () {
      var url =
        process.env.VUE_APP_VIHJAA_ENDPOINT +
        "/incident/attachment/" +
        this.$store.state.organization.orgId;
      var data = new FormData();
      this.fileList = this.$store.state.addedFiles;
      console.log("saving");
      for (let index in this.fileList) {
        var arr = this.fileList[index];
        var file = arr[0];
        data.append("file", file);
      }
      await this.axios
        .post(url, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((r) => {
          this.$store.commit("updateSendedFiles", r.data.items);
        })
        .catch((e) => {
          console.log(e);
          this.canSave = false;
          this.showAlert = true;
        });
    },
  },
};
</script>
